<!--
 * @Description: H5主入口页面
 * @Author: yangsheng
 * @Date: 2021-11-08 15:08:07
 * @LastEditors: Don 18137802527@163.com
 * @LastEditTime: 2023-05-16 18:17:41
-->
<template>
    <div class="page">
        <van-loading v-if="loading" :size="50" type="circular" color="rgb(100, 100, 100)" />
    </div>
</template>

<script>
import { findLatestScanTypeInfo, findScanTrueHistory } from '@/assets/js/apolloGql.js'
export default {
    data() {
        return {
            loading: true,
            scanType: null,
            newDate: null,
            deviceType: JSON.parse(window.localStorage.getItem('deviceType'))
        }
    },
    created() {
        window.localStorage.removeItem('modelInfo')
        // 保存入口类型
        window.sessionStorage.setItem('entryType', 1)
        this.findLatestScanTypeInfo()
        window.localStorage.removeItem('createTime')
    },
    methods: {
        // 查询最新扫描信息
        async findLatestScanTypeInfo() {
            console.log(`查询最后一次报告信息${this.deviceType} ${this.$route.query.type ? parseInt(this.$route.query.type) : null}`)
            const result = await this.$apollo
                .query({
                    query: findLatestScanTypeInfo,
                    variables: {
                        deviceType: this.deviceType,
                        type: this.$route.query.type ? parseInt(this.$route.query.type) : null
                    },
                    fetchPolicy: 'network-only'
                })

            const resultData = result.data.findLatestScanTypeInfo

            if (resultData.code === 200) {
                this.newDate = resultData.data
                // 保存扫描信息
                this.updateModelInfo(resultData.data)

                if ((resultData.data.bmBdaScanInfo ? resultData.data.bmBdaScanInfo.result !== 1 : true) &&
                    (resultData.data.bmBiaScanInfo ? resultData.data.bmBiaScanInfo.result !== 1 : true) &&
                    (resultData.data.bsScanInfo ? resultData.data.bsScanInfo.result !== 1 : true) &&
                    resultData.data.shoulderJointEval !== 1) {
                    this.findScanTrueHistory(resultData.data.deviceType)
                } else {
                    //   跳转到报告详情页面
                    this.$router.push({
                        path: '/report'
                    })
                }

            } else if (resultData.code === 404) {
                if (!window.localStorage.getItem('deviceType')) window.localStorage.setItem('deviceType', 2);
                // 获取不到最新报告跳转到无报告页面
                this.$router.push({
                    path: '/exception/report'
                })
            } else {
                this.$router.push({
                    path: '/exception/report'
                })
            }
        },

        // 历史记录接口
        findScanTrueHistory(deviceType) {
            this.$apollo
                .query({
                    query: findScanTrueHistory,
                    variables: {
                        deviceType: deviceType
                    },
                    fetchPolicy: 'network-only'
                })
                .then(res => {
                    // 接口修可能设计到deviceType
                    let history = res.data.findScanTrueHistory.data
                    if (!history) {
                        this.$router.push({
                            path: '/exception/report'
                        })
                        return
                    }
                    const scanResult = {
                        scanId: history.scanId,
                        biaStatus: history.biaStatus,
                        bdaStatus: history.bdaStatus,
                        evalStatus: history.evalStatus,
                        bsShoulderScanResult: history.bsShoulderStatus,
                        girthMeasure: history.bsShoulderStatus,
                        shoulderJointEval: history.shoulderJointEval,
                        scanTime: history.scanTime
                    }
                    let modelInfo = Object.assign(this.newDate, scanResult)
                    window.localStorage.setItem('modelInfo', JSON.stringify(modelInfo))
                    // 跳转到报告详情页面
                    setTimeout(() => {
                        this.$router.push({
                            path: '/report'
                        })
                    }, 400);
                })
                .catch(err => {
                    console.log(err)
                })
        },
        // 更新modelInfo，缓存
        updateModelInfo(data) {
            const modelInfo = {
                scanId: data.scanId,
                memberId: data.memberId,
                biaMeasure: data.biaMeasure,
                staticEval: data.staticEval,
                createTime: data.createTime,
                girthMeasure: data.girthMeasure,
                shoulderEval: data.shoulderJointEval,
                biaStatus: data.bmBiaScanInfo ? data.bmBiaScanInfo.result : 4,
                bdaStatus: data.bmBdaScanInfo ? data.bmBdaScanInfo.result : 4,
                evalStatus: data.bsScanInfo ? data.bsScanInfo.result : 4,
                bsShoulderScanResult: data.bsShoulderScanInfo ? data.bsShoulderScanInfo.result : 4,
                scanTime: data.scanTime,
                deviceId: data.deviceId
            }
            window.localStorage.setItem('modelInfo', JSON.stringify(modelInfo))
            window.localStorage.setItem('deviceType', JSON.stringify(data.deviceType))
        }
    }
}
</script>

<style scoped lang="less">
.page {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#262849), to(#07090f));
    background-image: linear-gradient(180deg, #262849, #07090f);
}
</style>
 